import React from 'react';

function Project() {
  // TODO: Fix styles on mobile ==> font sizes, quotes
  return (
    <div className='project'>
      <div className='container'>
        <div className='row quote'>
          <div className='col-xs-12 col-sm-8 col-sm-offset-2'>
            <blockquote>
              When you're hitchhiking, every person you meet is a new voice. We
              all have our own stories - moments of happiness and of suffering,
              of loneliness and of love. These are the voices we want to share
              with you.
            </blockquote>
          </div>
        </div>
        <div className='col-xs-12'>
          <p>
            Trail of Voices is a collection of stories from people around the
            world, a series of snapshots into the lives of the people we meet
            traveling. These are the people who pick us up hitchhiking, take us
            into their homes for a few nights, or just meet us somewhere along
            the way. They each have a story to tell (often more than one) and we
            want to share their voices.
          </p>
          <p>
            Both hitchhiking and couchsurfing offer an incredible way to connect
            with people around the world - not only with our drivers and hosts,
            but also with their friends, families, and with the travelers who
            stay with university. Who picks us up? Well, just about everyone,
            from families on a Sunday drive to lawyers on business trips, from
            university students to retired grandparents. Most not only open
            their cars to us, but also their lives. Our New Year's resolution
            for 2017 is to share these stories, to show who picks us up, takes
            us in and helps us on our way.
          </p>
          <p>
            So come along, join us as we hitchhike around the world. We've got
            over a hundred voices to be published (with more every time we
            travel), so check back often for new stories.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Project;
