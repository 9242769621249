import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';

import './Header.css';

function Header() {
  return (
    <Navbar bg='light' expand='lg' collapseOnSelect>
      <Navbar.Brand>
        <img
          className='navbar-icon'
          src='/favicons/favicon.ico'
          alt='Trail of Voices Logo'
        />
        TRAIL OF VOICES
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='basic-navbar-nav' />
      <Navbar.Collapse id='basic-navbar-nav'>
        <Nav className='mr-auto'>
          <Nav.Link as={NavLink} to='/map' className='nav-link' eventKey='1'>
            Stories
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            to='/project'
            className='nav-link'
            eventKey='2'>
            The Project
          </Nav.Link>
          <Nav.Link as={NavLink} to='/story' className='nav-link' eventKey='3'>
            Our Story
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            to='/contact'
            className='nav-link'
            eventKey='4'>
            Contact
          </Nav.Link>
          <Nav.Link href='http://www.facebook.com/trailofvoices/'>
            <img
              src='/icons/fb.png'
              className='navbar-icon'
              alt='Facebook Link'
            />
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
