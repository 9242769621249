import mockStories from './stories.mock.json';

export default {
  imageData: undefined,
  devMode: !window.navigator.onLine,
  stories: undefined,

  getStory: async function (id) {
    const stories = this.stories || (await this.getStoriesAndImages()).stories;
    const story = stories.find(
      (story) => story.properties.id.toString() === id
    );

    return story && story.properties;
  },

  getStoriesAndImages: function () {
    if (this.stories && this.imageData) {
      return Promise.resolve({
        stories: this.stories,
        imageData: this.imageData
      });
    }

    return this.fetchStoriesAndImages()
      .then(this.parseStoriesAndImages.bind(this))
      .then(this.filterStories)
      .then(this.saveDataToState.bind(this));
  },

  fetchStoriesAndImages: function () {
    return this.devMode ? Promise.resolve(mockStories) : this.fetchFromCMS();
  },

  fetchFromCMS: () =>
    fetch('https://cdn.contentful.com/spaces/8zsogdbei79s/entries/', {
      method: 'GET',
      withCredentials: true,
      headers: {
        Authorization: 'Bearer ' + process.env.REACT_APP_CONTENTFUL_PRODUCTION
      }
    }).then((response) => response.json()),

  parseStoriesAndImages: function ({ items = [], includes: { Asset = [] } }) {
    const imageData = Asset.map((image) => ({
        id: image.sys.id,
        url_large: this.devMode
          ? '/mock-profile-400.jpg'
          : image.fields.file.url + '?w=400&h=400',
        url_small: this.devMode
          ? '/mock-profile-120.jpg'
          : image.fields.file.url + '?w=120&h=120',
        ...image.fields
      })),
      stories = items.map((story) => ({
        type: 'Feature',
        properties: {
          ...story.fields,
          cluster: false
        },
        geometry: {
          type: 'Point',
          coordinates: [
            parseFloat(story.fields.home.lon),
            parseFloat(story.fields.home.lat)
          ]
        }
      }));

    return { stories, imageData };
  },

  saveDataToState: function ({ stories, imageData }) {
    this.stories = stories;
    this.imageData = imageData;

    return { stories, imageData };
  },

  filterStories: function ({ stories, imageData }) {
    const filteredStories = [...stories]
      .sort((a, b) => a.properties.id - b.properties.id)
      .map((story, index, array) => {
        story.properties.next = array[index + 1] || {};
        story.properties.previous = (index && array[index - 1]) || {};

        return story;
      });

    return { stories: filteredStories, imageData };
  }
};
