import React from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';

import storiesService from '../../services/stories';
import { ChevronRight, ChevronLeft, X } from 'react-bootstrap-icons';

function StoryPanel({ images }) {
  const [story, setStory] = React.useState();
  const [image, setImage] = React.useState();
  const { storyId } = useParams();
  const history = useHistory();

  React.useEffect(() => {
    const navWithArrowsListener = (event) => {
      if (event.key === 'ArrowLeft' && story.previous.properties) {
        history.push(`/map/${story.previous.properties.id}`);
      } else if (event.key === 'ArrowRight' && story.next.properties) {
        history.push(`/map/${story.next.properties.id}`);
      }
    };

    storiesService.getStory(storyId).then(setStory);
    window.addEventListener('keydown', navWithArrowsListener);

    return () => {
      window.removeEventListener('keydown', navWithArrowsListener);
    };
  }, [storyId, history, story]);

  // TODO: Investigate faster image delivery when scrolling through stories
  React.useEffect(() => {
    const image =
      images &&
      images.length &&
      story &&
      story.photo &&
      images.find((image) => image.id === story.photo.sys.id);

    setImage(image);
  }, [story, images]);

  const makeLink = (type) => {
    // Guard
    if (!story || !story[type] || !story[type].properties) {
      return null;
    }

    let icon =
      type === 'previous' ? (
        <ChevronLeft size={80} />
      ) : (
        <ChevronRight size={80} />
      );

    // No arrow if there's no story to navigate to
    if (!story[type].properties.id) {
      icon = null;
    }

    return story[type].properties.id ? (
      <Link
        to={`${story[type].properties.id}`}
        className={`story-nav story-nav-${type}`}>
        {icon}
      </Link>
    ) : (
      <div className={`story-nav story-nav-${type}`}>{icon}</div>
    );
  };

  return story ? (
    <div className='story-panel'>
      {makeLink('previous')}
      <div className='main-content'>
        {image && (
          <img src={image.url_large} alt={image.title} className='profile' />
        )}
        <div className='information'>
          <span>{story.name}</span>
          <span>
            {story.city}, {story.country}
          </span>
        </div>
        <div className='information'>{story.howWeMet}</div>
        <div className='detailed'>{story.detailed}</div>
        <div className='notes'>{story.notes}</div>
      </div>
      {makeLink('next')}
      <Link to='/map'>
        <button className='close-button'>
          <X />
        </button>
      </Link>
    </div>
  ) : null;
}

export default StoryPanel;
