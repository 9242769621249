import React from 'react';
import GoogleMap from 'google-map-react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import useSupercluster from 'use-supercluster';

import ClusterMarker from '../map/ClusterMarker';
import Marker from '../map/StoryMarker';
import StoryPanel from '../map/StoryPanel';

import storiesService from '../../services/stories';

function Map() {
  const [stories, setStories] = React.useState([]);
  const [openStory, setOpenStory] = React.useState();
  const [imageData, setImageData] = React.useState([]);
  const [zoom, setZoom] = React.useState(2);
  const [bounds, setBounds] = React.useState(null);
  const { clusters, supercluster } = useSupercluster({
    points: stories,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 }
  });
  const mapRef = React.useRef();
  const match = useRouteMatch();

  React.useEffect(() => {
    storiesService.getStoriesAndImages().then(({ stories, imageData }) => {
      setImageData(imageData);
      setStories(stories);
    });
  }, []);

  /**
   * Return silver map styling
   */
  const mapOptions = () => ({
    fullscreenControl: false,
    styles: [
      {
        elementType: 'geometry',
        stylers: [
          {
            color: '#f5f5f5'
          }
        ]
      },
      {
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161'
          }
        ]
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#f5f5f5'
          }
        ]
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#bdbdbd'
          }
        ]
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee'
          }
        ]
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575'
          }
        ]
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5'
          }
        ]
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e'
          }
        ]
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            color: '#ffffff'
          }
        ]
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575'
          }
        ]
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
          {
            color: '#dadada'
          }
        ]
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161'
          }
        ]
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e'
          }
        ]
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5'
          }
        ]
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee'
          }
        ]
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            color: '#c9c9c9'
          }
        ]
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e'
          }
        ]
      }
    ]
  });

  const expandCluster = ({
    id,
    geometry: {
      coordinates: [lng, lat]
    }
  }) => () => {
    if (!mapRef || !mapRef.current) {
      return;
    }
    const expansionZoom = Math.min(
      20,
      supercluster.getClusterExpansionZoom(id)
    );

    mapRef.current.setZoom(expansionZoom);
    mapRef.current.panTo({ lat, lng });
  };

  const storyMarkers = clusters.map((story) => {
    const { cluster: isCluster, point_count: pointCount } = story.properties;
    const isOpen =
      openStory && !isCluster && openStory.id === story.properties.id;

    return isCluster ? (
      <ClusterMarker
        pointCount={pointCount}
        onClick={expandCluster(story)}
        key={story.id}
        lat={story.geometry.coordinates[1]}
        lng={story.geometry.coordinates[0]}
      />
    ) : (
      <Marker
        key={story.id}
        story={story.properties}
        images={imageData}
        lat={story.geometry.coordinates[1]}
        lng={story.geometry.coordinates[0]}
        isOpen={isOpen}
        openStory={setOpenStory} // TODO: pan/zoom to story if it's open in the panel (NOT popover)
      />
    );
  });

  const captureMapProperties = ({ zoom, bounds }) => {
    setZoom(zoom);
    setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
  };

  const captureMapRef = ({ map }) => {
    mapRef.current = map;
  };

  return (
    <div className='map-container' id='main-map'>
      <GoogleMap
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS }}
        defaultCenter={{ lat: 30, lng: 0 }}
        defaultZoom={zoom}
        options={mapOptions}
        yesIWantToUseGoogleMapApiInternals
        hoverDistance={20}
        onChange={captureMapProperties}
        onGoogleApiLoaded={captureMapRef}>
        {storyMarkers}
      </GoogleMap>
      <Switch>
        <Route path={`${match.path}/:storyId`}>
          <StoryPanel images={imageData} />
        </Route>
      </Switch>
    </div>
  );
}

export default Map;
