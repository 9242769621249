import React from 'react';

function Contact() {
  return (
    <div className='contact'>
      <div className='container'>
        <div className='jumbotron' id='contactNew'>
          <h5>Want to get in touch?</h5>
          Send us a message on
          <a href='https://www.facebook.com/trailofvoices'>Facebook</a> or email
          us at
          <a href='mailto:info@trailofvoices.com'>info@trailofvoices.com</a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
