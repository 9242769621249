import React from 'react';

function OurStory() {
  return (
    <div className='our-story'>
      <div className='container'>
        <div className='row'>
          <img
            src='images/about_us.jpg'
            title=''
            alt='Jon and Nora hitchhiking'
          />
        </div>

        <div className='row quote'>
          <div className='col-xs-10 col-sm-8 col-sm-offset-2'>
            <blockquote>
              When someone asks Nora why she's here in Amsterdam, she has an
              easy answer: she's studying. Mine's even better - I just have to
              point to her.
            </blockquote>
          </div>
          <div className='col-xs-2 quote-name'>Jon</div>
        </div>

        <div className='col-xs-12'>
          <p>
            It all started on a warm winter's day. In Mexico, of course. Where
            else would a German hitchhiker meet a Canadian pilot? Jon was
            relaxing in a hostel after a 24 hour bus ride from Tijuana (he
            hadn't discovered hitchhiking yet) when he looked up to find a
            pretty girl smiling at him from the doorway. Nora was just happy to
            find someone who spoke English after the last three weeks thumbing
            her way down Baja, but he didn't need to know that yet.
          </p>
          <p>
            Later that week, after the many nights of Carnival, Nora let on that
            she was also heading towards Mexico City on her way to South America
            - and she was looking for someone to hitchhike with. Naturally, Jon
            pretended that he was a pro and, by a stroke of luck, she believed
            him. The next day, they set off in the back of a pickup truck on
            what would turn into the trip of a lifetime. Sure, neither of them
            made it to South America as initially planned, but Panama's not too
            far away.
          </p>
          <p>
            Instead, their trip took a turn eastward, taking them across the
            Atlantic to Portugal by boat. They continued through Europe over the
            summer, making it as far as Hungary, where Nora proved herself a
            prize-winning hitchhiker in the annual HungaroHitch hitchhiking race
            (Jon, on the other hand, finished three hours late, knowing he still
            had much to learn). Their travels over for the year, they then
            turned back towards Amsterdam, where they have both settled down.
            Well, as much as these two can settle - unable to sit still for
            long, they often venture out to explore new corners of the world.
          </p>
          <p>
            It was during one of these trips, sitting in a beach camp in Sinai,
            that the idea to share the stories of the people they meet
            hitchhiking took hold. Before they knew it, it grew into a project
            not only to share their drivers' stories, but also those of their
            hosts, of other travelers, and of anyone they meet who had a story
            to tell. From that day on the beach, Trail of Voices was born.
          </p>
        </div>

        <div className='row quote'>
          <div className='col-xs-10 col-sm-8 col-sm-offset-2'>
            <blockquote>
              Whenever I tell people that I hitchhike, they worry about me. I
              want to share that there is no reason to worry. I mean, look at
              the people we meet!
            </blockquote>
          </div>
          <div className='col-xs-2 quote-name'>Nora</div>
        </div>
      </div>
    </div>
  );
}

export default OurStory;
