import React from 'react';
import { Popover } from 'react-bootstrap';
import { Link, useRouteMatch } from 'react-router-dom';

import MarkerIcon from './MarkerIcon';

function Marker({ story, images, isOpen, openStory }) {
  const [placement, setPlacement] = React.useState('top');
  const [popoverClass, setPopoverClass] = React.useState('hidden');
  const ref = React.useRef();
  const match = useRouteMatch();

  const image =
    story &&
    story.photo &&
    images &&
    images.find((image) => image.id === story.photo.sys.id);

  const onHoverStart = () => openStory(story),
    onHoverEnd = () => openStory(false);

  // Assign popover's position if `isOpen` is true, otherwise hide the popover
  React.useEffect(() => {
    if (isOpen) {
      const markerPosition = ref.current.getBoundingClientRect(),
        mapContainerPosition = document
          .getElementById('main-map')
          .getBoundingClientRect(),
        inTopHalf = markerPosition.top < mapContainerPosition.height * 0.55,
        inLeftHalf = markerPosition.left < mapContainerPosition.width / 2;

      setPopoverClass(inLeftHalf ? 'right' : 'left');
      setPlacement(inTopHalf ? 'bottom' : 'top');
    } else {
      setPopoverClass('hidden');
    }
  }, [isOpen]);

  return (
    <div
      className='story-marker'
      onMouseEnter={onHoverStart}
      onMouseLeave={onHoverEnd}
      onTouchStart={onHoverStart}
      ref={ref}>
      <MarkerIcon />
      <Link
        to={`${match.url.replace(/\/$/, '')}/${story.id.toString()}`}
        onClick={onHoverEnd}>
        <Popover className={`preview ${popoverClass}`} placement={placement}>
          <Popover.Content>
            {image && <img src={image.url_small} alt={image.title} />}
            <div className='brief'>{story.brief}</div>
          </Popover.Content>
        </Popover>
      </Link>
    </div>
  );
}

export default Marker;
