import React from 'react';

import { CircleFill } from 'react-bootstrap-icons';

function ClusterMarker({ pointCount, onClick }) {
  return (
    <div className='cluster-marker' onClick={onClick}>
      <CircleFill />
      <div className='point-count'>{pointCount}</div>
    </div>
  );
}

export default ClusterMarker;
