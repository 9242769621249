import React from 'react';
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect
} from 'react-router-dom';

import Contact from './components/pages/Contact';
import Header from './components/header/Header';
import Map from './components/pages/Map';
import OurStory from './components/pages/OurStory';
import Project from './components/pages/Project';

import './App.scss';

function App() {
  return (
    <div className='App'>
      <Router>
        <Header />
        <Switch>
          <Route exact path='/'>
            <Redirect to='/map' />
          </Route>
          <Route path='/map'>
            <Map />
          </Route>
          <Route path='/project'>
            <Project />
          </Route>
          <Route path='/story'>
            <OurStory />
          </Route>
          <Route path='/contact'>
            <Contact />
          </Route>
          <Route path='/latest'></Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
